import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddWebhook, TestWebhookEndpoint } from '../../services/integration/webhook';

// Define the async thunk for adding a webhook
export const addWebhook = createAsyncThunk('integration/addWebhook', async (webhookData, { rejectWithValue }) => {
  try {
    webhookData.service_name = 'webhook';
    const response = await AddWebhook(webhookData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data || error.message);
  }
});

// Define the async thunk for testing a webhook
export const testWebhook = createAsyncThunk('integration/testWebhook', async (webhookData, { rejectWithValue }) => {
  try {
    const response = await TestWebhookEndpoint(webhookData);
    return response;
  } catch (error) {
    return rejectWithValue(error || error.message);
  }
});

// Define the initial state for the slice
const initialState = {
  status: '', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  data: null, // To store webhook data if needed
  testWebhookResult: null, // To store the result of the test webhook
  testWebhookStatus: '', // 'idle' | 'loading' | 'succeeded' | 'failed'
  testWebhookError: null,
};

// Create the slice
const webhookSlice = createSlice({
  name: 'integration/webhook',
  initialState,
  reducers: {
    resetWebhookState: state => {
      state.status = '';
      state.error = null;
      state.data = null;
      state.testWebhookResult = null;
      state.testWebhookStatus = '';
      state.testWebhookError = null;
    },
  },
  extraReducers: builder => {
    builder
      // Handle addWebhook actions
      .addCase(addWebhook.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addWebhook.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload; // Store the webhook data
      })
      .addCase(addWebhook.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      // Handle testWebhook actions
      .addCase(testWebhook.pending, state => {
        state.testWebhookStatus = 'loading';
        state.testWebhookError = null;
      })
      .addCase(testWebhook.fulfilled, (state, action) => {
        state.testWebhookStatus = 'succeeded';
        state.testWebhookResult = action.payload; // Store the test webhook result
      })
      .addCase(testWebhook.rejected, (state, action) => {
        state.testWebhookStatus = 'failed';
        // state.testWebhookError = action.payload || action.error.message;
        state.testWebhookError = action.payload.message;
   });
  },
});

export const { resetWebhookState } = webhookSlice.actions; // Export the resetWebhookState action
export default webhookSlice.reducer; // Export the reducer from webhookSlice
