import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CustomerService from '../services/customer'

// export const fetchcustomer = createAsyncThunk('customer/fetchcustomer', async () => {
//   const customer = await CustomerService.getcustomer();
// //   console.log("Fetched customer:", customer);
//   return customer;
// });

const initialState = {
  picture: null,
  firstName: '',
  lastName: '',
  email: '',
  name: '',
  phoneNumber: '',
  role: '',
  sub: '',
}
const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setUserProfileSlice (state, action) {
      const { picture, name, given_name, family_name, email, sub } = action.payload
      // Update the state properties with the correct values
      state.picture = picture
      state.firstName = given_name
      state.lastName = family_name
      state.email = email
      state.name = name
      state.sub = sub
    },
    updateUserProfileSlice (state, action) {
      const { firsName, lastName, phoneNumber, email, role } = action.payload
      // state.picture = picture;
      state.firstName = firsName
      state.lastName = lastName
      state.email = email
      state.phoneNumber = phoneNumber
      state.role = role
      state.name = `${firsName} ${lastName}`
    },
  },
})

export const { setUserProfileSlice, updateUserProfileSlice } = userProfileSlice.actions

export default userProfileSlice.reducer // Export the reducer from userProfileSlice
