import { TokenService } from '../authService'

const backendUrl = process.env.REACT_APP_BACKENDURL

const handleResponse = async response => {
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.message || 'An error occurred while processing your request.')
  }
  return response.json()
}

export const getIntegrations = async () => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backendUrl}api/integrations/getCustomeIntegrations`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return await handleResponse(response)
  } catch (error) {
    console.error('Error fetching integrations:', error)
    throw error
  }
}

export const DeleteCustomerIntegration = async data => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backendUrl}api/integrations/deleteCustomerIntegration`, {
      method: 'POST', // Changed to POST to align with typical delete operations
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    const res = await handleResponse(response)
    if (!res) throw new Error('An error occurred while processing your request.')

    return res
  } catch (error) {
    console.error('Error deleting customer integration:', error)
    throw error
  }
}


export const editCustomerIntegration = async data => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backendUrl}api/integrations/editCustomerIntegration`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    return await handleResponse(response)
  } catch (error) {
    console.error('Error editing customer integration:', error)
    throw error
  }
}