import { TokenService } from './authService'
const backend_url = process.env.REACT_APP_BACKENDURL

export const GetWadResultByscanId = async (scanData) => {
  try {
    const token = TokenService.getToken()

    const response = await fetch(`${backend_url}api/wad/getWadResultByscanId`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scanData),
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }

    const techObj = await response.json()
    return techObj
  } catch (error) {
    console.log('Error getting technologies details:', error)
    throw error
  }
}
