import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const {getIdTokenClaims, getRefreshToken ,
    loginWithRedirect,
    signupWithRedirect,
    checkSession,
    logout,
    isAuthenticated,
    user,
    isLoading,
    getAccessTokenSilently,error
  } = useAuth0()

  const handleLogin = async () => {
    try {
      await loginWithRedirect()
    } catch (error) {
      console.error('Login error:', error)
    }
  }
  const handleRegistration = async () => {
    try {
      await signupWithRedirect()
    } catch (error) {
      console.error('Registration error:', error)
    }
  }
  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  const handleCheckSession = async () => {
    try {
      await checkSession()
      console.log('Session successfully checked.')
    } catch (error) {
      console.error('Error checking session:', error)
    }
  }

  const authContextValue = useMemo(
    () => ({
      getIdTokenClaims, getRefreshToken ,
      user,
      isLoading,
      login: handleLogin,
      logout: handleLogout,
      signUp: handleRegistration,
      isAuthenticated,
      getAccessTokenSilently,
      checkSession: handleCheckSession,error
    }),
    [getIdTokenClaims, getRefreshToken ,
      user,
      isLoading,
      handleLogin,
      handleLogout,
      handleRegistration,
      isAuthenticated,
      getAccessTokenSilently,
      handleCheckSession,error
    ],
  )

  return (
    <>
      {isLoading ? (
        <CSpinner color='primary' />
      ) : (
        <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
      )}
    </>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider')
  }
  return context
}
