import { configureStore, current, getDefaultMiddleware } from '@reduxjs/toolkit'
import targetsReducer from './slices/targets'
import generalReducer from './slices/general'
// import scansApi from "./services/scansService";
import vulnerabilitiesReducer from './slices/vulnerabilities'
import schedulingReducer from './slices/scheduling'
import scanReducer from './slices/scan'
import mapSiteReducer from './slices/mapSite'
import customerReducer from './slices/customer'
import reportReducer from './slices/report'
import userProfileReduser from './slices/userProfile'
import technologReducer from './slices/technologies'
import authenticationReducer from './slices/authentication'
import globalAsyncThunkErrorHandler from './slices/globalAsyncThunkErrorHandler' // Adjust the path accordingly
import errorReducer from './slices/error'
import auth0Utils from './slices/auth0_utils'
import webhookIntegration from './slices/integrations/webhook'
import integration from './slices/integrations/integration'
const store = configureStore({
  reducer: {
    // [scansApi.reducerPath]: scansApi.reducer,
    scan: scanReducer,
    targets: targetsReducer,
    general: generalReducer,
    vulnerabilities: vulnerabilitiesReducer,
    scheduling: schedulingReducer,
    mapsite: mapSiteReducer,
    customer: customerReducer,
    report: reportReducer,
    userProfile: userProfileReduser,
    tech: technologReducer,
    authentication: authenticationReducer,
    error: errorReducer,
    auth: auth0Utils,
    integrations: integration,
    webhook: webhookIntegration,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(scansApi.middleware)
  middleware: [...getDefaultMiddleware(), globalAsyncThunkErrorHandler],
})

export default store
