import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  visibleCreateScan: true,
  breadcrumbsHeader: true,
  breadcrumbsContent: true,
  networkError: false,
}

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    set(state = initialState, { type, ...rest }){
      switch (type) {
        case 'general/set':
          return { ...state, ...rest }
        default:
          return state
      }
    },
  },
})

export const { set } = generalSlice.actions

export default generalSlice.reducer
