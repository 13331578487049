import { clearError } from './error'

const handleAsyncThunkErrorsMiddleware =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    if (action.type && action.type.endsWith('/fulfilled')) {
      // Handle success actions if needed
      const relatedRejectedActionType = action.type.replace('/fulfilled', '/rejected')
      const state = getState()
      if (state.error.errors[relatedRejectedActionType]) {
        dispatch(clearError({ type: relatedRejectedActionType }))
      }
    } else if (action.type && action.type.endsWith('/rejected')) {
      let errorMessage = action.payload
      if (
        (action.error && action.error.message === 'Failed to fetch') ||
        errorMessage === 'Failed to fetch' ||
        errorMessage === 'Network error: Server is unreachable'
      ) {
        console.log('action', action)
        dispatch({ type: 'general/set', networkError: true })
      } else if (
        action.type === 'targets/addTarget/rejected' ||
        action.type === 'targets/updateTarget/rejected'
      ) {
        errorMessage = action.payload.errorMessage
        if (errorMessage === 'Invalid request. Please check your input and try again.') {
          dispatch({
            type: 'error/setError',
            payload: { type: action.type, message: action.payload },
          })
        }
      } else if (errorMessage === 'Unauthorized: Invalid token or session expired') {
        dispatch({ type: 'general/set', logoutRequest: true })
      } else if (errorMessage === 'Invalid request. Please check your input and try again.') {
        dispatch({
          type: 'error/setError',
          payload: { type: action.type, message: errorMessage },
        })
      }
    }

    return next(action)
  }

export default handleAsyncThunkErrorsMiddleware
