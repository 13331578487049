import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errors: {}, // Object to store errors
}

export const errorSlice  = createSlice({
  name: 'error',
  initialState,
  reducers: {
    set (state, action) {
      return { ...state, ...action.payload }
    },
    setError (state, action) {
      const { type, message } = action.payload
      return {
        ...state,
        errors: {
          ...state.errors,
          [type]: message,
        },
      }
    },
    clearError (state, action) {
      const { type } = action.payload
      const newErrors = { ...state.errors }
      delete newErrors[type]
      return {
        ...state,
        errors: newErrors,
      }
    },
  },
})

export const { set, setError, clearError } = errorSlice .actions
export const selectErrorByType = (state, type) => state.error.errors[type];

export default errorSlice .reducer
