import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetAllVulnerabilities,
  GetAllVulnerabilitiesOfCustomerbyScanIds,
  GetVulnerabilitiesIdExtend,
  GetImageToken,
  GetTopVulnerabilitiesOfCustomerbyScanIds,
} from '../../dl/services/vulnerabilityService'

export const fetchVulnerabilities = createAsyncThunk(
  'vulnerabilities/fetchVulnerabilities',
  async ({ rejectWithValue }) => {
    try {
      const vulnerabilities = await GetAllVulnerabilities()
      return vulnerabilities
    } catch (err) {
      return rejectWithValue(err.message) // Return error message on failure
    }
  },
)

export const fetchAllVulnerabilitiesOfCustomerbyScanIds = createAsyncThunk(
  'vulnerabilities/fetchAllVulnerabilitiesOfCustomerbyScanIds',
  async ({ page, limit, sort, filter }, { getState, rejectWithValue }) => {
    try {
      // const customerId = getState().customer.customer.id // Get the last customer's ID
      const vulnerabilities = await GetAllVulnerabilitiesOfCustomerbyScanIds(
        page,
        limit,
        sort,
        filter,
      )
      return vulnerabilities
    } catch (err) {
      return rejectWithValue(err.message) // Return error message on failure
    }
  },
)

export const fetchTopVulnerabilitiesOfCustomerbyScanIds = createAsyncThunk(
  'vulnerabilities/fetchTopVulnerabilitiesOfCustomerbyScanIds',
  async ({ page, limit, sort, filter, topCount }, { getState, rejectWithValue }) => {
    try {
      // const customerId = getState().customer.customer.id // Get the last customer's ID
      const vulnerabilities = await GetTopVulnerabilitiesOfCustomerbyScanIds(
        page,
        limit,
        sort,
        filter,
        topCount,
      )
      return vulnerabilities
    } catch (err) {
      return rejectWithValue(err.message) // Return error message on failure
    }
  },
)

export const getVulnerabilitiesIdExtend = createAsyncThunk(
  'vulnerabilities/getVulnerabilitiesIdExtend',
  async (id, { rejectWithValue }) => {
    try {
      const vulnerabilities = await GetVulnerabilitiesIdExtend(id)
      return vulnerabilities
    } catch (err) {
      return rejectWithValue(err.message) // Return error message on failure
    }
  },
)

export const getImageToken = createAsyncThunk(
  'vulnerabilities/getImageToken',
  async (token_data, { rejectWithValue }) => {
    try {
      const imageToken = await GetImageToken(token_data)

      return imageToken
    } catch (err) {
      return rejectWithValue(err.message) // Return error message on failure
    }
  },
)

const initialState = {
  allVulnerabilities: [],
  topVulnerabilities: [],
  status: 'idle',
  error: null,
  vulnObj: null,
}

const vulnerabilitiesSlice = createSlice({
  name: 'vulnerabilities',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchVulnerabilities.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchVulnerabilities.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.allVulnerabilities = action.payload
      })
      .addCase(fetchVulnerabilities.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(getImageToken.pending, state => {
        state.status = 'loading'
      })
      .addCase(getImageToken.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(getImageToken.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(getVulnerabilitiesIdExtend.pending, state => {
        state.status = 'loading'
      })
      .addCase(getVulnerabilitiesIdExtend.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.vulnObj = action.payload
      })
      .addCase(getVulnerabilitiesIdExtend.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchAllVulnerabilitiesOfCustomerbyScanIds.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAllVulnerabilitiesOfCustomerbyScanIds.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.allVulnerabilities = action.payload.vulnerabilities
      })
      .addCase(fetchAllVulnerabilitiesOfCustomerbyScanIds.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchTopVulnerabilitiesOfCustomerbyScanIds.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchTopVulnerabilitiesOfCustomerbyScanIds.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.topVulnerabilities = action.payload.vulnerabilities
      })
      .addCase(fetchTopVulnerabilitiesOfCustomerbyScanIds.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
    // .addCase(addVulnerability.fulfilled, (state, action) => {
    //   state.allVulnerabilities.push(action.payload);
    // })

    // .addCase(updateVulnerability.fulfilled, (state, action) => {
    //   const { vulnerabilityId, vulnerabilityData } = action.meta.arg;
    //   const vulnerabilityIndex = state.allVulnerabilities.findIndex((vulnerability) => vulnerability.id === vulnerabilityId);
    //   if (vulnerabilityIndex !== -1) {
    //     state.allVulnerabilities[vulnerabilityIndex] = { ...state.allVulnerabilities[vulnerabilityIndex], ...vulnerabilityData };
    //   }
    // })

    // .addCase(deleteVulnerabilities.fulfilled, (state, action) => { // Changed action name to 'deleteVulnerabilities'
    //   const vulnerabilityIds = action.payload; // Updated to use the array of deleted vulnerability IDs
    //   state.allVulnerabilities = state.allVulnerabilities.filter((vulnerability) => !vulnerabilityIds.includes(vulnerability.id)); // Filter out the deleted vulnerabilities
    // });
  },
})
export default vulnerabilitiesSlice.reducer
