import { createSlice } from '@reduxjs/toolkit'
import schedulingsData from '../../views/my-data/Scheduling'

const initialState = {
  allSchedulings: schedulingsData,
  // owner: 'Tansy',
}

export const schedulingsSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    deleteSchedulings(state, action)
    {
      console.log("slice deleteSchedulings")
      const deleteIds = action.payload.selectedItems.map(i => i.id);
      state.allSchedulings=state.allSchedulings.filter(s => !deleteIds.includes(s.id));

    },
    addScheduling(state, action)
    {
      console.log("slice addScheduling")
      const newScheduling=action.payload
      newScheduling['id']=state.allSchedulings.length+1;
      const date=new Date();
      newScheduling['date']= date.getDate() + "/" + (date.getMonth()+1) + "/" +date.getFullYear();
      // newScheduling['owner']= state.owner;
      // date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      console.log("new scheduling: ", newScheduling)
      state.allSchedulings.push(newScheduling);
    },
    editScheduling(state, action)
    {
      console.log("slice editScheduling")

      const objIndex = state.allSchedulings.findIndex((obj => obj.id == action.payload.id));
      state.allSchedulings[objIndex] = action.payload
    }
    // setAllschedulings(state, action) {
    //   state.allSchedulings = action.payload;
    // },
    // getAllschedulings(state, action) {
    //   state.allSchedulings = action.payload;
    // },
  },
})
// https://63a196bdba35b96522e23ecf.mockapi.io/schedulings
export const { deleteSchedulings, addScheduling, editScheduling } = schedulingsSlice.actions

export default schedulingsSlice.reducer