import { getLocalStorageItem } from './appStorageService'
import { TokenService } from './authService'

class CustomerService {
  backend_url = process.env.REACT_APP_BACKENDURL
  token = TokenService.getToken()

  async getCustomerByEmail (email) {
    try {
      const response = await fetch(`${this.backend_url}api/customer/getCustomerByEmail`, {
        method: 'POST', // Use POST request
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
          Authorization: `Bearer ${this.token}`, // Pass the token value
        },
        body: JSON.stringify({ email: email }), // Convert the data object to a JSON string})
      })
      if (response.status === 401) return response.status

      const customer = await response.json()
      return customer
    } catch (error) {
      console.log('Error fetching getCustomerByEmail service:', error)
      throw error
    }
  }
}

export default new CustomerService()
