import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

// import socket from '../services/scanSocketService'
import {
  GetAllScans,
  GetScanComariosionByRisk,
  GetScanById,
  AddScan,
  HideScanRunById,
  StopScan,
  ReportSupport,
} from './../services/scanService'

export const fetchScans = createAsyncThunk(
  'scan/fetchScans', // Action type string
  async (_, {  rejectWithValue }) => {
    try {
      const scans = await GetAllScans() // Fetch scans based on customer ID
      return scans // Return fetched scans on success
    } catch (err) {
      return rejectWithValue(err.message) // Return error message on failure
    }
  },
)

export const getScanById = createAsyncThunk(
  'scan/getScanById',
  async (data, { rejectWithValue }) => {
    try {
      const scans = await GetScanById(data);
      return scans;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// Repeat the same pattern for other async thunks:
export const getScanById_2 = createAsyncThunk(
  'scan/getScanById_2',
  async (data, { rejectWithValue }) => {
    try {
      const scans = await GetScanById(data);
      return scans;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getScanComariosionByRisk = createAsyncThunk(
  'scan/getScanComariosionByRisk',
  async (comparedata, { rejectWithValue }) => {
    try {
      const scans = await GetScanComariosionByRisk(comparedata);
      return scans;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addScan = createAsyncThunk(
  'scan/addScan',
  async (scanData, { rejectWithValue }) => {
    try {
      const newScan = await AddScan(scanData);
      return newScan;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const hideScanRunById = createAsyncThunk(
  'scan/hideScanRunById',
  async (data, { rejectWithValue }) => {
    try {
      const scan = await HideScanRunById(data);
      return scan;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const stopScan = createAsyncThunk(
  'scan/stopScan',
  async (data, { rejectWithValue }) => {
    try {
      const scan = await StopScan(data);
      return scan;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const reportSupport = createAsyncThunk(
  'scan/reportSupport',
  async (data, { rejectWithValue }) => {
    try {
      const scans = await ReportSupport(data);
      return scans;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

// const handleSocketEvent = dispatch => {
//   socket.on('updated-progress', data => {
//     // Handle the received scan details here
//     const { scan_id, progress_percent, status_progress } = data
//     console.log('updated-progress', data)
//     // Dispatch an action to update the Redux store's 'allScans' array
//     dispatch(updateAllScans({ scan_id, progress_percent, status_progress }))
//   })
// }

const initialState = {
  allScans: [],
  owner: 'Tansy',
  status: '',
  error: null,
  scanCommissionsByRisk: [],
  scanObj: null,
  scanName: '',
  targetDetails: {},
  passiveScan: true,
  deleteStatus: { status: false },
  stopStatus: { showAlert: false },
  addScanStatus: null,
  getScanByIdStatues: '',
}

const scansSlice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    resetScanObj: (state, action) => {
      state.scanObj = null
    },
    resetActionStatus: (state, action) => {
      const propertyToReset = action.payload

      if (state.hasOwnProperty(propertyToReset)) {
        // Reset the specified property to its initial state
        state[propertyToReset] =
          typeof initialState[propertyToReset] === 'object'
            ? { ...initialState[propertyToReset] }
            : initialState[propertyToReset]
      }
    },
    updateAllScans: (state, action) => {
      const { scan_id, progress_percent, scan_status_code, status_progress, end_date } =
        action.payload
      // Find the index of the scan with the matching scan_id
      const scanIndex = state.allScans.findIndex(scan => scan.scan_id === scan_id)

      // If the scan exists, update its progressbarvalue and status
      // if (scanIndex !== -1) {
      //   state.allScans[scanIndex].value = progress_percent
      //   state.allScans[scanIndex].status_progress = status_progress
      //   state.allScans[scanIndex].scan_status_code = scan_status_code
      //   state.allScans[scanIndex].endDate = end_date
      // }
      // Check if the scan_id matches scanObj.scan_id and update its parameters
      if (state.scanObj && state.scanObj.scan_id === scan_id) {
        state.scanObj.value = progress_percent
        state.scanObj.status_progress = status_progress
        state.scanObj.scan_status_code = scan_status_code
        state.scanObj.endDate = end_date
      }
    },
    updateScanName (state, action) {
      state.scanName = action.payload.scanName;
    },
    updateTargetDetails (state, action) {
      state.targetDetails = action.payload.targetDetails;
    },
    updatepassiveScan (state, action) {
      state.passiveScan = action.payload.passiveScan;

    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchScans.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchScans.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.allScans = action.payload
        // Debugging: Log the payload to see its content
        // Ensure payload is an array before calling .map
        if (Array.isArray(action.payload)) {
          const updatedScans = action.payload.map(scan => {
            // Assuming the timer field is a date string
            let localTime_start_date = scan.start_date
            let localTime_endDate = scan.endDate
            if (scan.start_date) {
              localTime_start_date = new Date(scan.start_date).toLocaleString()
            }
            if (scan.endDate) {
              localTime_endDate = new Date(scan.endDate).toLocaleString()
            }
            return { ...scan, start_date: localTime_start_date, endDate: localTime_endDate }
          })
          state.allScans = updatedScans
        } else {
          // Handle the case where payload is not an array
          state.allScans = []
        }
      })
      .addCase(fetchScans.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(addScan.pending, state => {
        state.addScanStatus = 'loading'
        state.error = null
      })
      .addCase(addScan.fulfilled, (state, action) => {
        state.addScanStatus = 'succeeded'
        state.allScans.push(action.payload) // Add the newly created scan to the state
      })
      .addCase(addScan.rejected, (state, action) => {
        state.addScanStatus = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(getScanComariosionByRisk.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getScanComariosionByRisk.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Handle the data from the fulfilled action as needed, e.g., store it in the state
        state.scanCommissionsByRisk = action.payload
      })
      .addCase(getScanComariosionByRisk.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(getScanById.pending, state => {
        state.getScanByIdStatues = 'loading'
        state.error = null
      })
      .addCase(getScanById.fulfilled, (state, action) => {
        state.getScanByIdStatues = 'succeeded'

        // Handle the data from the fulfilled action as needed, e.g., store it in the state
        const scanObj = action.payload
        let localTime_start_date = scanObj.start_date
        let localTime_endDate = scanObj.endDate
        if (scanObj.start_date) {
          localTime_start_date = new Date(scanObj.start_date).toLocaleString()
        }
        if (scanObj.endDate) {
          localTime_endDate = new Date(scanObj.endDate).toLocaleString()
        }
        state.scanObj = { ...scanObj, start_date: localTime_start_date, endDate: localTime_endDate }
        // Update the scan list
        const existingScanIndex = state.allScans.findIndex(scan => scan.scan_id === scanObj.scan_id)
        if (existingScanIndex !== -1) {
          state.allScans[existingScanIndex] = {
            ...scanObj,
            start_date: localTime_start_date,
            endDate: localTime_endDate,
          }
        } else {
          state.allScans.push({
            ...scanObj,
            start_date: localTime_start_date,
            endDate: localTime_endDate,
          })
        }
      })
      .addCase(getScanById.rejected, (state, action) => {
        state.getScanByIdStatues = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(getScanById_2.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getScanById_2.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Handle the data from the fulfilled action as needed, e.g., store it in the state
        const scanObj = action.payload
        let localTime_start_date = scanObj.start_date
        let localTime_endDate = scanObj.endDate
        if (scanObj.start_date) {
          localTime_start_date = new Date(scanObj.start_date).toLocaleString()
        }
        if (scanObj.endDate) {
          localTime_endDate = new Date(scanObj.endDate).toLocaleString()
        }
        // Update the scan list
        const existingScanIndex = state.allScans.findIndex(scan => scan.scan_id === scanObj.scan_id)
        if (existingScanIndex !== -1) {
          state.allScans[existingScanIndex] = {
            ...scanObj,
            start_date: localTime_start_date,
            endDate: localTime_endDate,
          }
        } else {
          state.allScans.push({
            ...scanObj,
            start_date: localTime_start_date,
            endDate: localTime_endDate,
          })
        }

        if (state.scanObj && state.scanObj.scan_id === scanObj.scan_id) {
          state.scanObj = { ...scanObj }
        }
      })
      .addCase(getScanById_2.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(hideScanRunById.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(hideScanRunById.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(hideScanRunById.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const scan_ids = action.meta.arg.scan_ids // Updated to use the array of deleted target IDs
        state.allScans = state.allScans.filter(scan => !scan_ids.includes(scan.scan_id)) // Filter out the deleted targets
        state.deleteStatus = action.payload
      })
      .addCase(stopScan.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(stopScan.fulfilled, (state, action) => {
        state.stopStatus = 'succeeded'
        if (action.payload.status === 'success') {
          const updatedStatusProgress = action.payload.data.status_progress

          if (state.scanObj && state.scanObj.scan_id === action.payload.data.id) {
            let localTime_start_date = state.scanObj.start_date
            let localTime_endDate = state.scanObj.endDate
            if (state.scanObj.start_date) {
              localTime_start_date = new Date(state.scanObj.start_date).toLocaleString()
            }
            if (state.scanObj.endDate) {
              localTime_endDate = new Date(state.scanObj.endDate).toLocaleString()
            }
            state.scanObj = {
              ...state.scanObj,
              status_progress: updatedStatusProgress,
              start_date: localTime_start_date,
              endDate: localTime_endDate,
            }
          }

          const updatedScans = state.allScans.map(scan => {
            // Check the condition you want to update based on, for example, scan.scan_id
            if (scan.scan_id === action.payload.data.id) {
              let localTime_start_date = scan.start_date
              let localTime_endDate = scan.endDate
              if (scan.start_date) {
                localTime_start_date = new Date(scan.start_date).toLocaleString()
              }
              if (scan.endDate) {
                localTime_endDate = new Date(scan.endDate).toLocaleString()
              }
              return {
                ...scan,
                status_progress: updatedStatusProgress,
                start_date: localTime_start_date,
                endDate: localTime_endDate,
              }
            }

            // Keep other objects unchanged
            return scan
          })
          state.allScans = updatedScans
          state.stopStatus = { status: 'success', showAlert: true }
        } else if (action.payload.status === 'error') state.stopStatus = action.payload
      })
      .addCase(stopScan.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload || action.error.message;
      })
      .addCase(reportSupport.pending, state => {
        state.status = 'loading'
      })
      .addCase(reportSupport.fulfilled, (state, action) => {
        state.status = 'succeeded'
      })
      .addCase(reportSupport.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
      })
  },
})
export const {
  updateAllScans,
  resetActionStatus,
  updateScanName,
  updatepassiveScan,
  updateTargetDetails,
  resetScanObj,
} = scansSlice.actions

export default scansSlice.reducer // Export the reducer from scansSlice
