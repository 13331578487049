import { TokenService } from './authService'

const backend_url = process.env.REACT_APP_BACKENDURL

export const SendEmail = async emailData => {
  // console.log('emailData', emailData)
  // console.log('emailData json',JSON.stringify(emailData))
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/email/send`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    })
    if (response.status === 401) return response.status

    const email_json = await response.json()
    // console.log('response',response)
    // console.log('response json',email_json)
    const returnedEmail = email_json
    return returnedEmail
  } catch (error) {
    console.log('Error adding email:', error)
    throw error
  }
}
