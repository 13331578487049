import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
// import store from './store'
import store from './dl/store'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Auth0Provider } from '@auth0/auth0-react'
import { useSelector, useDispatch } from 'react-redux'
import { AuthContextProvider } from './utils/autheContext'
// import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";

const container = document.getElementById('root')
const root = createRoot(container)

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const audience = process.env.REACT_APP_AUTH0_AUDIENCE

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: audience,
    }}
    // cacheLocation="localstorage"
    // useRefreshTokens={true}
    // cacheLocation={'memory'}
  >
    <Provider store={store}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </Provider>
  </Auth0Provider>,


)

reportWebVitals()
