import { TokenService } from './authService';
const backend_url = process.env.REACT_APP_BACKENDURL

export const AddReport = async (reportData) =>{
  // console.log('reportData', reportData)
  // console.log('reportData json',JSON.stringify(reportData))
  try {
    const token =TokenService.getToken()
    const response = await fetch(`${backend_url}api/report/create`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reportData),
    })
    if (response.status === 401) return response.status

    const report_json = await response.json()
    // console.log('response',response)
    // console.log('response json',report_json)
    const newReport = report_json
    return newReport
  } catch (error) {
    console.log('Error adding report:', error)
    throw error
  }
}