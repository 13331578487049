import { TokenService } from './authService'
const backend_url = process.env.REACT_APP_BACKENDURL

export const GetAllScans = async () => {
  try {
    const token = TokenService.getToken()
    // console.log('getAllScans service', token)

    const response = await fetch(`${backend_url}api/scan/getAll/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }

    const scans = await response.json()
    return scans
  } catch (error) {
    console.log('Error fetching scans:', error)
    throw error
  }
}

export const GetScanComariosionByRisk = async data => {
  try {
    const token = TokenService.getToken()

    const response = await fetch(`${backend_url}api/scan/compareScan`, {
      method: 'POST', // Use POST request
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const scans = await response.json()

    return scans
  } catch (error) {
    console.log('Error fetching scans:', error)
    throw error
  }
}

export const GetScanById = async data => {
  try {
    const token = TokenService.getToken()

    const response = await fetch(`${backend_url}api/scan/getScanById`, {
      method: 'POST', // Use POST request
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    return response.json()

    // console.log("getScanById, scans", scans)
  } catch (error) {
    console.log('Error fetching scan:', error)
    throw error
  }
}

export const AddScan = async scanData => {
  try {
    const token = TokenService.getToken()

    const response = await fetch(`${backend_url}api/scan/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scanData),
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while adding data.')
    }
    const newScan = await response.json()
    return newScan
  } catch (error) {
    console.log('Error adding scan:', error)
    throw error
  }
}

export const HideScanRunById = async data => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/scan/hideScanRunById`, {
      method: 'POST', // Use POST request
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string
    })

    if (response.status === 204) {
      // Deletion was successful, return true or a success message
      return { status: true, id: 0 }
    } else if (response.status === 207) {
      // Object not found, handle it as needed
      return { error: 'Some resources were not found', status: true, id: 1 }
    } else if (response.status === 403 || response.status === 401) {
      // Unauthorized or Forbidden, handle it as needed
      return { error: 'Unauthorized or Forbidden', status: true, id: 1 }
    } else if (response.status >= 400 && response.status < 500) {
      // Handle other client-side errors (4xx) with a generic message
      return { error: 'Client-side error occurred', status: true, id: 1 }
    } else if (response.status >= 500) {
      // Handle server-side errors (5xx) with a generic message
      return { error: 'Server-side error occurred', status: true, id: 1 }
    } else {
      // Handle unexpected or unknown status codes with a generic message
      return { error: 'An error occurred during deletion', status: true, id: 1 }
    }
  } catch (error) {
    console.error('Error deleteing scans:', error)
    throw error
  }
}

export const StopScan = async data => {
  try {
    const token = TokenService.getToken()

    const response = await fetch(`${backend_url}api/scan/stopScan`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (response.status === 401) return response.status

    const responseData = await response.json()

    if (!response.ok) {
      console.error('Error response from server:', responseData.error)
    } else {
      console.log('Success response from server:', response.status)
    }
    if (response.status === 200) {
      // Deletion was successful, return a success object
      return { status: 'success', data: responseData.data, showAlert: true }
    } else {
      // Handle other status codes, including errors
      if (response.status === 404) {
        // Object not found
        return {
          status: 'error',
          error: responseData.error || 'Resource not found',
          showAlert: true,
        }
      } else if (response.status === 403 || response.status === 401) {
        // Unauthorized or Forbidden
        return {
          status: 'error',
          error: responseData.error || 'Unauthorized or Forbidden',
          showAlert: true,
        }
      } else if (response.status >= 400 && response.status < 500) {
        // Other client-side errors
        return {
          status: 'error',
          error: responseData.error || 'Client-side error occurred',
          showAlert: true,
        }
      } else {
        // Server-side errors
        return {
          status: 'error',
          error: responseData.error || 'Server-side error occurred',
          showAlert: true,
        }
      }
    }
  } catch (error) {
    console.error('Error stopScan:', error)
    return { status: 'error', error: 'Internal server error' }
  }
}

export const ReportSupport = async data => {
  try {
    const token = TokenService.getToken()

    const response = await fetch(`${backend_url}api/scan/reportSupport`, {
      method: 'POST', // Use POST request
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while ReportSupport .')
    }
    response.json()
  } catch (error) {
    console.log('Error ReportSupport :', error)
    throw error
  }
}
