import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// export const fetchlastcustomer = createAsyncThunk('customer/fetchlastcustomer', async () => {
//   const customer = await CustomerService.getLastCustomer();
// //   console.log("Fetched customer:", customer);
//   return customer;
// });

const initialState = {
  authUsed : false,
  authType : '',
  authUrl : '',
  authUser : '',
  authPassword : '',
  authParams : '',
  authCookieName : '',
  authCookieValue : '',
  authHeaderName : '',
  authHeaderValue : '',
  authRealm : '',
};
const authenticationSlice = createSlice({
  name: 'authentication', 
  initialState,
  reducers: {
    setAuthenticationSlice(state, action) {
        const { authUsed, authType, authUrl,authUser,
            authPassword,authParams,authCookieName,authCookieValue,
            authHeaderName,authHeaderValue,authRealm} = action.payload;
      
        // Update the state properties with the correct values
        state.authUsed = authUsed;
        state.authType = authType;
        state.authUrl = authUrl;
        state.authUser = authUser;
        state.authPassword = authPassword;
        state.authParams = authParams;
        state.authCookieName = authCookieName;
        state.authCookieValue = authCookieValue;
        state.authHeaderName = authHeaderName;
        state.authHeaderValue = authHeaderValue;
        state.authRealm = authRealm;
      },
      updateAuthenticationSlice(state, action) {
        const { authUsed, authType, authUrl,authUser,
            authPassword,authParams,authCookieName,authCookieValue,
            authHeaderName,authHeaderValue,authRealm} = action.payload;
      
        // Update the state properties with the correct values
        state.authUsed = authUsed;
        state.authType = authType;
        state.authUrl = authUrl;
        state.authUser = authUser;
        state.authPassword = authPassword;
        state.authParams = authParams;
        state.authCookieName = authCookieName;
        state.authCookieValue = authCookieValue;
        state.authHeaderName = authHeaderName;
        state.authHeaderValue = authHeaderValue;
        state.authRealm = authRealm;
      },
  },
});

export const { setAuthenticationSlice, updateAuthenticationSlice } = authenticationSlice.actions

export default authenticationSlice.reducer; // Export the reducer from authenticationSlice