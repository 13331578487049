import { TokenService } from './authService'

const backend_url = process.env.REACT_APP_BACKENDURL

export const GetAllTargetsService = async () => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/target/getAllByCustomerId/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const targets = await response.json()
    return targets
  } catch (error) {
    console.log('Error fetching targets:', error)
    throw error
  }
}

export const AddTargetService = async targetData => {
  const token = TokenService.getToken()
  try {
    const response = await fetch(`${backend_url}api/target/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(targetData),
    })

    if (!response.ok) {
      let errorData
      try {
        errorData = await response.json()
      } catch (error) {
        throw new Error('An error occurred while fetching data.')
      }
      const errorMessage = errorData?.error || 'An error occurred while adding data.'
      const errorDetails = errorData?.details || []
      const statusCode = response.status
      throw { errorMessage, errorDetails ,statusCode} // Throw an object containing both errorMessage and errorDetails
    }

    const newTarget = await response.json()
    return newTarget
  } catch (error) {
    console.error('Error adding target:', error)
    throw error
  }
}

export const DeleteTargetByIdsService = async targetData => {
  const token = TokenService.getToken()
  try {
    const response = await fetch(`${backend_url}api/target/deleteByIds`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(targetData),
    })
    if (response.status === 401) return response.status

    if (response.status === 204) {
      // No content to return, indicate success with an empty object
      return { status: 204 }
    }
    // Assuming you're expecting JSON data, you can parse it
    const data = await response.json()
    // Instead of returning the entire response, return only the relevant data
    return data
  } catch (error) {
    console.log('Error deleteTargetByIds:', error)
    throw error
  }
}

export const UpdateTargetService = async targetData => {
  const token = TokenService.getToken()
  try {
    const response = await fetch(`${backend_url}api/target/updateById`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(targetData),
    })
    if (!response.ok) {
      let errorData
      try {
        errorData = await response.json()
      } catch (error) {
        throw new Error('An error occurred while fetching data.')
      }
      const errorMessage = errorData?.error || 'An error occurred while adding data.'
      const errorDetails = errorData?.details || []
      const statusCode = response.status
      throw { errorMessage, errorDetails ,statusCode} // Throw an object containing both errorMessage and errorDetails
    }

    const updatedTarget = await response.json()
    return updatedTarget
  } catch (error) {
    console.log('Error updating target:', error)
    throw error
  }
}

export const DeleteTargetService = async targetId => {
  const token = TokenService.getToken()
  try {
    const response = await fetch(`${backend_url}api/target/deleteById/${targetId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
    })
    if (response.status === 401) return response.status

    return targetId // Return the ID of the deleted target
  } catch (error) {
    console.log('Error deleting target:', error)
    throw error
  }
}
