import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getIntegrations, DeleteCustomerIntegration } from '../../services/integration/integration'
import { editCustomerIntegration as editCustomerIntegrationService } from '../../services/integration/integration'

import { AddWebhook } from '../../services/integration/webhook'

// Define the async thunk for fetching integrations
export const fetchIntegrations = createAsyncThunk('integration/fetchIntegrations', async () => {
  try {
    const response = await getIntegrations()
    return response
  } catch (error) {
    return Promise.reject(error)
  }
})

// Define the async thunk for adding a webhook
export const addWebhook = createAsyncThunk('integration/addWebhook', async webhookData => {
  try {
    webhookData.service_name = 'webhook'
    const response = await AddWebhook(webhookData)
    return response
  } catch (error) {
    return Promise.reject(error)
  }
})

// Define the async thunk for deleting a customer integration
export const deleteCustomerIntegration = createAsyncThunk(
  'integration/deleteCustomerIntegration',
  async data => {
    try {
      const response = await DeleteCustomerIntegration(data)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

// Define the async thunk for editing a customer integration
export const editCustomerIntegration = createAsyncThunk(
  'integration/editCustomerIntegration',
  async data => {
    try {
      const response = await editCustomerIntegrationService(data)
      return response
    } catch (error) {
      return Promise.reject(error)
    }
  },
)

// Define the initial state for the slice
const initialState = {
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  addWebhookStatus: '',
  editCustomerIntegrationStatus:'',
  error: null,
  integrations: [], // To store integrations data
}

// Create the slice
const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    resetAddWebhookStatusIntegrationtate: state => {
      state.addWebhookStatus = ''
      state.editCustomerIntegrationStatus = ''
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIntegrations.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.integrations = action.payload // Store the integrations data
      })
      .addCase(fetchIntegrations.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(addWebhook.pending, state => {
        state.addWebhookStatus = 'loading'
        state.error = null
      })
      .addCase(addWebhook.fulfilled, (state, action) => {
        state.addWebhookStatus = 'succeeded'
        state.integrations.push(action.payload) // Add the new webhook to the integrations array
      })
      .addCase(addWebhook.rejected, (state, action) => {
        state.addWebhookStatus = 'failed'
        state.error = action.error.message
      })
      .addCase(deleteCustomerIntegration.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteCustomerIntegration.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Remove the deleted integration from the array
        state.integrations = state.integrations.filter(
          integration => integration.id !== action.meta.arg.id,
        )
      })
      .addCase(deleteCustomerIntegration.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(editCustomerIntegration.pending, state => {
        state.editCustomerIntegrationStatus = 'loading'
        state.error = null
      })
      .addCase(editCustomerIntegration.fulfilled, (state, action) => {
        state.editCustomerIntegrationStatus = 'succeeded'
        // Update the edited integration in the array
        const index = state.integrations.findIndex(
          integration => integration.id === action.meta.arg.integration_id,
        )
        if (index !== -1) {
          state.integrations[index] = action.payload
        }
      })
      .addCase(editCustomerIntegration.rejected, (state, action) => {
        state.editCustomerIntegrationStatus = 'failed'
        state.error = action.error.message
      })
  },
})
export const { resetAddWebhookStatusIntegrationtate } = integrationSlice.actions; 

export default integrationSlice.reducer // Export the reducer from integrationSlice
