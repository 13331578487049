import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CustomerService from '../services/customer';
import { checkAuthenticate } from '../services/authenticatedLogin';

import store from '../store';

export const fetchCustomerData = createAsyncThunk('customer/fetchCustomerData', async (token) => {
  try {
    const customer = await checkAuthenticate(token);
    return customer;
  } catch (error) {
    console.error('Error fetching customer data:', error);
    throw error;
  }
});

const initialState = {
  customer: null,
  owner: 'Tansy',
  status: 'idle',
  error: null,
};

const customerSlice = createSlice({
  name: 'customer', 
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customer = action.payload;
      })
      .addCase(fetchCustomerData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default customerSlice.reducer; // Export the reducer from customerSlice
