// const SchedulingsData = [
//   {
//     "id": 1, "target": "http://nature.com",
//     "scan_name": 'Important scan',
//     "start_date": "3/22/2022",
//     "repeat": "Monthly",
//     "time_scan": "22:49",
//     "time_zone": "Europe/Prague",
//     "owner": "Henrietta Pidgeley",
//     "tag": "pt tools", "activated": true
//   },
//   { "id": 2, "target": "http://tumblr.com", "scan_name": 'Important scan', "start_date": "4/6/2022", "repeat": "Yearly", "time_scan": "21:48", "time_zone": "Europe/Warsaw", "owner": "Keir Crickett", "tag": "pt tools", "activated": false },
//   { "id": 3, "target": "http://patch.com", "scan_name": 'Important scan', "start_date": "8/12/2022", "repeat": "Never", "time_scan": "14:59", "time_zone": "Asia/Jakarta", "owner": "Hector Noriega", "tag": "pt tools", "activated": true },
//   { "id": 4, "target": "https://noaa.gov", "scan_name": 'Important scan', "start_date": "4/26/2022", "repeat": "Often", "time_scan": "14:35", "time_zone": "America/Mexico_City", "owner": "Leanna Jiru", "tag": "pt tools", "activated": false },
//   { "id": 5, "target": "http://biblegateway.com", "scan_name": 'Important scan', "start_date": "11/15/2022", "repeat": "Seldom", "time_scan": "4:21", "time_zone": "America/Sao_Paulo", "owner": "Ailey Beams", "tag": "pt tools", "activated": true },
//   { "id": 6, "target": "https://shareasale.com", "scan_name": 'Important scan', "start_date": "9/28/2022", "repeat": "Once", "time_scan": "13:42", "time_zone": "America/Argentina/Tucuman", "owner": "Webster Sizland", "tag": "pt tools", "activated": true },
//   { "id": 7, "target": "https://deliciousdays.com", "scan_name": 'Important scan', "start_date": "9/9/2022", "repeat": "Seldom", "time_scan": "9:30", "time_zone": "America/Bogota", "owner": "Husain Corbert", "tag": "pt tools", "activated": false },
//   { "id": 8, "target": "http://imgur.com", "scan_name": 'Important scan', "start_date": "11/17/2022", "repeat": "Monthly", "time_scan": "18:09", "time_zone": "Europe/Sofia", "owner": "Yetty Allaway", "tag": "pt tools", "activated": true },
//   { "id": 9, "target": "https://soundcloud.com", "scan_name": 'Important scan', "start_date": "4/10/2022", "repeat": "Seldom", "time_scan": "21:11", "time_zone": "Asia/Yekaterinburg", "owner": "Staford Betjeman", "tag": "pt tools", "activated": true },
//   { "id": 10, "target": "https://independent.co.uk", "scan_name": 'Important scan', "start_date": "5/22/2022", "repeat": "Often", "time_scan": "8:39", "time_zone": "Asia/Makassar", "owner": "Vin Hancock", "tag": "pt tools", "activated": true }]

// export default SchedulingsData

const SchedulingsData = [
  {
    id: 1,
    target: 'https://neopix.co.il',
    scan_name: 'Neopix pt',
    start_date: '10/27/2022',
    repeat: 'Monthly',
    time_scan: '22:49',
    time_zone: 'Europe/Prague',
    owner: 'Hillel',
    tag: 'pt tools',
    activated: true,
  },
  {
    id: 0,
    target: 'https://neopix.co.il',
    scan_name: 'Important scan',
    start_date: '12/27/2022',
    repeat: 'Monthly',
    time_scan: '21:48',
    time_zone: 'Europe/Warsaw',
    owner: 'Hillel',
    tag: 'pt tools',
    activated: true,
  },
  {
    id: 1,
    target: 'https://www.deshlytics.com/',
    scan_name: 'Important scan',
    start_date: '12/04/2022',
    repeat: 'Never',
    time_scan: '14:59',
    time_zone: 'Asia/Jakarta',
    owner: 'Shirel',
    tag: 'pt tools',
    activated: false,
  },
  {
    id: 2,
    target: 'https://noaa.gov',
    scan_name: 'Important scan',
    start_date: '1/14/2022',
    repeat: 'Daily',
    time_scan: '14:35',
    time_zone: 'America/Mexico_City',
    owner: 'Adam Levi',
    tag: 'pt tools',
    activated: false,
  },
  {
    id: 3,
    target: 'http://factory70.co.il',
    scan_name: 'Important scan',
    start_date: '1/14/2022',
    repeat: 'Daily',
    time_scan: '04:21',
    time_zone: 'America/Sao_Paulo',
    owner: 'Andrew',
    tag: 'pt tools',
    activated: false,
  },
  {
    id: 5,
    target: 'https://glnbot.com/',
    scan_name: 'Important scan',
    start_date: '11/6/2022',
    repeat: 'Never',
    time_scan: '13:42',
    time_zone: 'America/Argentina/Tucuman',
    owner: 'Alex',
    tag: 'pt tools',
    activated: false,
  },
  {
    id: 6,
    target: 'https://glnbot.com/',
    scan_name: 'Important scan Repeat Never',
    start_date: '11/9/2022',
    repeat: 'Never',
    time_scan: '09:30',
    time_zone: 'America/Bogota',
    owner: 'Alex',
    tag: 'pt tools',
    activated: false,
  },
]

export default SchedulingsData
