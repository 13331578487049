// auth0_utilsService.js

export const changePasswordService = async (email) => {
    const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
  
    const url = `https://${auth0Domain}/dbconnections/change_password`;
  
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: client_id,
        email: email,
        connection: 'Username-Password-Authentication',
      }),
    };
  
    try {
      const response = await fetch(url, options);
      
      if (!response.ok) {
        const errorText = await response.text(); // Read the response as text
        console.error(`Error ${response.status}: ${errorText}`); // Log detailed error information
        throw new Error('Failed to send password reset email. Please try again later.'); // Return a generic error message
      }
  
      const data = await response.text(); // Read the response as text
      return 'Password reset email sent successfully';
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };
  