import { TokenService } from './authService'
const backend_url = process.env.REACT_APP_BACKENDURL

export const GetAllVulnerabilities = async data => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/vulnerability/getAllVulnerabilities`, {
      method: 'POST', // Use POST request
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string})
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const vulnerabilities = await response.json()
    return vulnerabilities
  } catch (error) {
    console.log('Error fetching vulnerabilities:', error)
    throw error
  }
}
export const GetVulnerabilitiesIdExtend = async id => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/vulnerability/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const vulnerabilitiess = await response.json()
    return vulnerabilitiess
  } catch (error) {
    console.log('Error fetching vulnerabilitiess:', error)
    throw error
  }
}
export const GetTopVulnerabilitiesOfCustomerbyScanIds = async (
  page,
  limit,
  sort,
  filter,
  topCount,
) => {

  try {
    const token = TokenService.getToken()
    // Serialize and encode the sort and filter parameters
    const encodedSort = encodeURIComponent(JSON.stringify(sort))
    const encodedFilter = encodeURIComponent(JSON.stringify(filter))
    // Construct the URL with the serialized sort and filter parameters
    const url = `${backend_url}api/vulnerability/getAllVulnerabilitiesOfCustomerbyScanIds?page=${page}&limit=${limit}&sort=${encodedSort}&filter=${encodedFilter}&topCount=${topCount}`;

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const vulnerabilitiess = await response.json()
    return vulnerabilitiess
  } catch (error) {
    console.log('Error fetching vulnerabilitiess:', error)
    throw error
  }
}

export const GetAllVulnerabilitiesOfCustomerbyScanIds = async (page, limit, sort, filter) => {

  try {
    const token = TokenService.getToken()
    // Serialize and encode the sort and filter parameters
    const encodedSort = encodeURIComponent(JSON.stringify(sort))
    const encodedFilter = encodeURIComponent(JSON.stringify(filter))
    // Construct the URL with the serialized sort and filter parameters
    const url = `${backend_url}api/vulnerability/getAllVulnerabilitiesOfCustomerbyScanIds?page=${page}&limit=${limit}&sort=${encodedSort}&filter=${encodedFilter}`

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const vulnerabilitiess = await response.json()
    return vulnerabilitiess
  } catch (error) {
    console.log('Error fetching vulnerabilitiess:', error)
    throw error
  }
}
export const GetImageToken = async data => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/blob/getImageToken`, {
      method: 'POST', // Use POST request
      headers: {
        Authorization: `Bearer ${token}`,

        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(data), // Convert the data object to a JSON string})
    })
    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.error || 'An error occurred while fetching data.')
    }
    const imageToken = await response.json()
    return imageToken
  } catch (error) {
    console.log('Error fetching image token:', error)
    throw error
  }
}
