let TOKEN = null;

export const TokenService = {
  setToken: (token) => {
    TOKEN = token;
    // Cookies.set('access_token', token, { secure: true, sameSite: 'Strict', httpOnly: false });
  },

  getToken: () => {
    // if (!TOKEN) {
    //   TOKEN = Cookies.get('access_token');
    // }
    return TOKEN;
  },

  clearToken: () => {
    TOKEN = null;
    // Cookies.remove('access_token');
  },
};
