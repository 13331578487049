import { TokenService } from '../authService'

const backend_url = process.env.REACT_APP_BACKENDURL

export const AddWebhook = async webhookData => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/integrations/webhook/add`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(webhookData),
    })

    if (!response.ok) {
      // Handle other possible errors
      const errorData = await response.json()
      throw new Error(errorData.message || 'An error occurred while adding the webhook.')
    }

    const result = await response.json()
    return result
  } catch (error) {
    console.log('Error adding webhook:', error)
    throw error
  }
}

export const TestWebhookEndpoint = async webhookData => {
  try {
    const token = TokenService.getToken()
    const response = await fetch(`${backend_url}api/integrations/webhook/testWebhook`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(webhookData),
    })
    if (!response.ok) {
      // Handle other possible errors
      const errorData = await response.json()
      throw new Error(errorData.error && errorData.error.message || 'An error occurred while adding the webhook.')
    }
    const result = await response.json()
    return result
  } catch (error) {
    console.log('Error adding webhook:', error)
    throw error
  }
}
