// passwordSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { changePasswordService } from '../services/auth0_utilsService'

// Define async thunk
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const email = state.userProfile.email
      const response = await changePasswordService(email)
      return response
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

// Define initial state
const initialState = {
  status: '',
  error: null,
}

// Create slice
const passwordSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  reducers: {
    resetStatus: state => {
      state.status = ''
      state.error = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(changePassword.pending, state => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(changePassword.fulfilled, state => {
        state.status = 'succeeded'
        state.error = null
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
  },
})
export const { resetStatus } = passwordSlice.actions;

export default passwordSlice.reducer
