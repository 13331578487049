import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetAllTargetsService,
  AddTargetService,
  DeleteTargetByIdsService,
  UpdateTargetService,
  DeleteTargetService,
} from '../services/targetsService'

export const fetchTargets = createAsyncThunk(
  'targets/fetchTargets',
  async (_, { rejectWithValue }) => {
    try {
      const targets = await GetAllTargetsService()
      return targets
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

export const addTarget = createAsyncThunk(
  'targets/addTarget',
  async (targetData, { rejectWithValue, getState }) => {
    try {
      debugger
      const state = getState()
      const owner = state.userProfile?.name || ""  // Use empty string if name is not present
      targetData.owner = owner
      const newTarget = await AddTargetService(targetData)
      return newTarget
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteTargetByIds = createAsyncThunk('targets/deleteTargetByIds', async targetData => {
  const target = await DeleteTargetByIdsService(targetData)
  return target
})

export const updateTarget = createAsyncThunk(
  'targets/updateTarget',
  async (targetData, { rejectWithValue }) => {
    try {
      const updatedTarget = await UpdateTargetService(targetData)
      return updatedTarget
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const deleteTargets = createAsyncThunk('targets/deleteTargets', async targetIds => {
  const deletePromises = targetIds.map(targetId => DeleteTargetService(targetId))

  const results = []
  for (const promise of deletePromises) {
    const result = await promise
    results.push(result)
  }

  return results
})

const initialState = {
  allTargets: [],
  owner: 'Tansy',
  status: 'idle',
  error: null,
  deleteTargets: null,
}

const targetsSlice = createSlice({
  name: 'targets',
  initialState,
  reducers: {
    resetDeleteTargets: state => {
      state.deleteTargets = null
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTargets.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchTargets.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        // Ensure payload is an array before calling .map
        if (Array.isArray(action.payload)) {
          state.allTargets = action.payload.map(target => {
            return {
              ...target,
              create_date: new Date(target.create_date).toLocaleString('en-US', {
                timeZone: userTimeZone,
              }),
            }
          })
        } else {
          console.error('Payload is not an array:', action.payload)
          // Handle the case where payload is not an array
          state.allTargets = []
        }
      })
      .addCase(fetchTargets.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(addTarget.fulfilled, (state, action) => {
        // Conflict- Target already exists.
        if (action.payload.statusCode === 409) {
          console.log('action.payload-', action.payload)
        } else {
          const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

          // Convert timestamp to user's local time zone
          action.payload.create_date = new Date(action.payload.create_date).toLocaleString(
            'en-US',
            { timeZone: userTimeZone },
          )

          // Add the modified payload to the state
          state.allTargets.push(action.payload)
        }
      })
      .addCase(deleteTargetByIds.pending, state => {
        state.status = 'loading'
      })
      .addCase(deleteTargetByIds.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const payload = action.payload
        // state.deleteTargets = action.payload
        const target_ids = action.meta.arg.targetIds
        state.deleteTargets = action.payload
        // console.log('action2', action.payload.status)
        // Updated to use the array of deleted target IDs
        if (action.payload.status === 204) {
          state.allTargets = state.allTargets.filter(target => !target_ids.includes(target.id))
        } else {
          state.allTargets = state.allTargets.filter(
            target => action.payload.results.status !== 204,
          )
        }
      })
      .addCase(deleteTargetByIds.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(updateTarget.fulfilled, (state, action) => {
        const { id } = action.meta.arg
        const targetIndex = state.allTargets.findIndex(target => target.id === id)
        if (targetIndex !== -1) {
          state.allTargets[targetIndex] = { ...state.allTargets[targetIndex], ...action.payload }
        }
      })
      .addCase(deleteTargets.fulfilled, (state, action) => {
        // Changed action name to 'deleteTargets'
        const targetIds = action.payload // Updated to use the array of deleted target IDs
        state.allTargets = state.allTargets.filter(target => !targetIds.includes(target.id)) // Filter out the deleted targets
      })
  },
})
export const { resetDeleteTargets } = targetsSlice.actions

export default targetsSlice.reducer
