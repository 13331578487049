import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import {AddReport} from '../services/reportService'
import {SendEmail} from '../services/emailService'
// import socket from '../services/scanSocketService'


export const addReport = createAsyncThunk('report/addReport', async (data, { getState }) => {
  const scan_id = data['scan_id']
  const targetName = data['targetName']

  const customerUrl = getState().customer.customer.web_page // Get the last customer's ID
  if (!getState().customer.customer) {
    throw new Error('No customer found')
  }
  //console.log('targetname: ',targetName)
  // console.log(3)
  const reportData = {scan_id:scan_id,target_name:targetName,customer_url:customerUrl}
  // console.log(4)
  const newReport = await AddReport(reportData)
  // console.log(5)
  // console.log('Added Report:', newReport)
  return newReport
})

export const sendEmail = createAsyncThunk('report/sendEmail', async (emailData) => {
  const email = await SendEmail(emailData);
  return email;
});

// const handleSocketEvent = dispatch => {
//   socket.on('updated-progress', data => {
//     // Handle the received scan details here
//     const { scan_id, progress_percent, status_progress } = data
//     console.log('updated-progress', data)
//     // Dispatch an action to update the Redux store's 'allScans' array
//     dispatch(updateAllScans({ scan_id, progress_percent, status_progress }))
//   })
// }

const initialState = {
  status: '',
  error: null,
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
     
      .addCase(addReport.pending, state => {
        state.addReportStatus = 'loading'
        state.error = null
      })
      .addCase(addReport.fulfilled, (state, action) => {
        state.addReportStatus = 'succeeded'
        
      })
      .addCase(addReport.rejected, (state, action) => {
        state.addReportStatus = 'failed'
        state.error = action.error.message
      })
  },
})

export default reportSlice.reducer // Export the reducer from scansSlice
