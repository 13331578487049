  export const checkAuthenticate = async (token) => {
    try {
        // Assuming you have the token stored somewhere
        const backend_url = process.env.REACT_APP_BACKENDURL
        const response = await fetch(`${backend_url}api/authenticate`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            // Add any other headers if needed
          },
        });
  
        const customer = await response.json();
        return customer;
      } catch (error) {
        console.log('Error checkAuthenticate service:', error);
        throw error;
      }
  };