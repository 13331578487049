import { TokenService } from './authService'
const backend_url = process.env.REACT_APP_BACKENDURL
export const GetAllMapSite = async scanData => {
  try {
    const token = TokenService.getToken()

    // const response = await fetch(`${backend_url}api/mapsite/${scan_id}`, {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    //     // Add any other headers if needed
    //   },
    // })

    const response = await fetch(`${backend_url}api/mapsite/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(scanData),
    })
    if (response.status === 401) return response.status

    const mapsite = await response.json()
    return mapsite
  } catch (error) {
    console.log('Error fetching mapsite:', error)
    throw error
  }
}

