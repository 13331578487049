import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import './scss/style.scss'
import { useSelector } from 'react-redux'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const NWError = React.lazy(() => import('./views/pages/network-error/PageNetworkError'))

const ProtectedRoute = React.lazy(() => import('./utils/protectiveRoutes'))
// Email App
// const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))
// const Home = React.lazy(() => import('./views/pages/home/home'))
const VerifyEmail = React.lazy(() => import('./views/pages/verifyEmail/verifyEmail.js'))
const ApprovedUser = React.lazy(() => import('./views/pages/approved-user/approved-user.js'))
// const CallbackPage = React.lazy(() => import('./views/pages/pagecallback/Pagecallback'))

const App = () => {
  // const navigate = useNavigate()

  const networkError = useSelector(state => state.general.networkError)

  // const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)

  // useEffect(() => {
  //   console.log('isAuthenticated App:', isAuthenticated)
  // }, [isAuthenticated])
  // useEffect(() => {
  //   if (networkError) {
  //     navigate('/networkError')
  //     // dispatch(clearNetworkError()) // Clear error after navigation
  //   }
  // }, [networkError])

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* <Route path='/' element={<Home/>} /> */}
          {/* <Route exact path='/register' name='Register Page' element={<Register />} /> */}
          {/* <Route exact path='/404' name='Page 404' element={<Page404 />} />
          <Route exact path='/500' name='Page 500' element={<Page500 />} /> */}
          {/* <Route path='/apps/email/*' name='Email App' element={<EmailApp />} /> */}
          <Route exact path='/login' name='Login Page' element={<Login />} />
          {/* <Route path='*' element={<Navigate to='/login' replace />} /> */}
          {/* <Route exact path='/500' name='Page 500' element={<Page500 />} /> */}
          {/* {networkError && (
            <Route exact path='/networkError' name='Page 500' element={<NWError />} />
          )} */}

          {networkError && (
            <Route
              path='/networkError'
              name='Page 500'
              element={<ProtectedRoute element={<NWError />} />}
            />
          )}

          {/* {networkError && (
            <Route
              path='/networkError'
              name='Page 500'
              element={<ProtectedRoute element={<NWError />} />}
            />
          )} */}

          {networkError && (
            <Route path='*' name='Page 500' element={<ProtectedRoute element={<NWError />} />} />
          )}

          {/* =======ProtectedRoute============ */}
          {/* <Route path="/callback" element={<CallbackPage />} /> */}

          {!networkError && (
            <Route path='*' element={<ProtectedRoute element={<DefaultLayout />} />} />
          )}
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
