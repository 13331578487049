import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetAllMapSite } from '../services/mapSiteService'

export const fetchMapSite = createAsyncThunk(
  'mapSite/fetchMapSite',
  async (scan_id, { getState }) => {
    const scanObj = getState().scan.scanObj // Get the last customer's ID
    if (scanObj && scanObj.scan_id === scan_id) {
      const data = {
        scan_id: scan_id,
      }
      const mapSite = await GetAllMapSite(data)
      return mapSite
    }
    return []
  },
)

const initialState = {
  allMapSite: [],
  owner: 'Tansy',
  status: 'idle',
  error: null,
}

const mapSiteSlice = createSlice({
  name: 'mapsite',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMapSite.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchMapSite.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.allMapSite = action.payload
      })
      .addCase(fetchMapSite.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export default mapSiteSlice.reducer // Export the reducer from mapSiteSlice
