import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetWadResultByscanId } from '../services/technologiesService'

export const getWadResultByscanId = createAsyncThunk(
  'tech/getWadResultByscanId',
  async (scan_id, { rejectWithValue }) => {
    try {
      const data = { scan_id }
      const techObj = await GetWadResultByscanId(data)
      return techObj
    } catch (err) {
      return rejectWithValue(err.message)
    }
  },
)

const initialState = {
  technologyObj: null,
  status: '',
  error: null, // Add an error field to the initial state
}

const technologySlice = createSlice({
  name: 'tech',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWadResultByscanId.pending, (state) => {
        state.status = 'loading'
        state.error = null // Reset the error state when loading
      })
      .addCase(getWadResultByscanId.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.technologyObj = action.payload
        state.error = null; // Reset the error state on success
      })
      .addCase(getWadResultByscanId.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload // Store the error message from rejectWithValue
      })
  },
})

export default technologySlice.reducer
